import React, { useEffect, useState } from "react";
import v1, { v2 } from "../../services/axios";
// import ConfirmModal from "../../ui/ConfirmModal";
import InlineConfirmModal from "../../ui/InlineConfirmModal";
import QueuedProjectsModal from "../../ui/QueuedProjectsModal";
import "./index.scss";

export function DefectCard({
  index,
  project,
  isQueued,
  uniqueId,
  getProjects,
  defaultChecked,
  handleCheck,
}) {
  const [defectText, setDefectText] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [borderClass, setBorderClass] = useState("");
  const pdfViewer = window.PDFViewerApplication.pdfViewer;
  const { currentPageNumber } = pdfViewer;
  const disabled =
    (project.text || project.inspection_problem_comment) === defectText;

  const highlightColors = [
    "highlight-red",
    "highlight-yellow",
    "highlight-blue",
    "highlight-green",
    "highlight-accentBlue",
    "highlight-orange",
    "highlight-gray",
  ];
  const colors = [
    "defect-border-red",
    "defect-border-yellow",
    "defect-border-blue",
    "defect-border-green",
    "defect-border-accentBlue",
    "defect-border-orange",
    "defect-border-gray",
  ];
  // let colorsIndex = index < colors.length ? index : 0;

  useEffect(() => {
    if (!defectText) {
      setDefectText(project.inspection_problem_comment || project.text);
    }
    getBorderClass();
  }, [project]);

  function getBorderClass() {
    let attempts = 0;
    const getHighlights = () => {
      const textLayerLoaded =
        pdfViewer &&
        pdfViewer._pages &&
        pdfViewer._pages[currentPageNumber - 1] &&
        pdfViewer._pages[currentPageNumber - 1].textLayer;

      if (textLayerLoaded) {
        const defect =
          defectText || project.inspection_problem_comment || project.text;

        const { matchedQueries } =
          pdfViewer._pages[currentPageNumber - 1].textLayer;

        for (const query of matchedQueries) {
          const text = query.text.trim();
          if (text.includes(defect) || defect.includes(text)) {
            const index = highlightColors.indexOf(query.color);
            setBorderClass(colors[index]);
            return;
          }
        }

        if (borderClass === "" && attempts < 3) {
          attempts++;
          setTimeout(getHighlights, 1000);
          return;
        }
      }
    };

    if (
      pdfViewer &&
      pdfViewer._pages &&
      pdfViewer._pages[currentPageNumber - 1] &&
      pdfViewer._pages[currentPageNumber - 1].textLayer
    ) {
      getHighlights();
    }
  }

  function formatText(text) {
    const formattedText = text.replace(/\0/g, "").replace(/  +/g, " ");
    setDefectText(formattedText);
  }

  function handleQueue() {
    setLoading(true);
    const text = defectText.replace(/\0/g, "").replace(/  +/g, " ").trim();

    if (text.length) {
      const defect = {
        text: text,
        pageStart: currentPageNumber,
        pageEnd: currentPageNumber,
      };
      let queuedProjects = JSON.parse(
        localStorage.getItem("queued-projects")
      ) || { [uniqueId]: [] };
      const keys = Object.keys(queuedProjects);

      if (keys[0] === uniqueId) {
        queuedProjects[uniqueId].push(defect);
        localStorage.setItem("queued-projects", JSON.stringify(queuedProjects));
        handleDelete();
      } else {
        setShowConfirmModal(true);
      }
    }
  }

  async function handleDelete() {
    setLoading(true);
    try {
      if (!isQueued) {
        const id = project.domoreport_project_log_id;
        let url = `/domoreport/uploader/project/${id}`;
        if (project.defect_id) {
          url = `/domoreport/uploader/project/${id}?defect=${project.defect_id}`;
        }

        const { status } = await v1.delete(url);
        if (status === 200) {
          setShowDeleteModal(false);
          setShowConfirmModal(false);
          removeHighlight();
          getProjects();
          setLoading(false);
        }
      } else {
        let queuedProjects = JSON.parse(
          localStorage.getItem("queued-projects")
        ) || { [uniqueId]: [] };

        if (queuedProjects[uniqueId]) {
          const index = queuedProjects[uniqueId].findIndex((queued) => {
            return (
              queued.text === project.text &&
              queued.pageStart === project.pageStart
            );
          });

          if (index !== -1) {
            queuedProjects[uniqueId].splice(index, 1);
            localStorage.setItem(
              "queued-projects",
              JSON.stringify(queuedProjects)
            );
          }
          setShowDeleteModal(false);
          setShowConfirmModal(false);
          getProjects();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  function removeHighlight() {
    const { pdfViewer, initialized, downloadComplete } =
      window.PDFViewerApplication;

    if (initialized && downloadComplete) {
      const pages = pdfViewer._pages;
      const pageNumber = pdfViewer._currentPageNumber - 1;

      if (pages[pageNumber] && pages[pageNumber].textLayer) {
        const spans = pages[pageNumber].textLayer.textDivs;
        spans.map((span) => {
          const spanText = span.innerText
            .replaceAll(/[^a-zA-Z0-9 ]+/g, "")
            .replace(/\0/g, "")
            .replace(/  +/g, " ")
            .trim();
          const projectText = project.inspection_problem_comment
            .replaceAll(/[^a-zA-Z0-9 ]+/g, "")
            .replace(/\0/g, "")
            .replace(/  +/g, " ")
            .trim();

          if (spanText.search(projectText) !== -1) {
            span.style.backgroundColor = "transparent";
            span.style.padding = 0;
          }
        });
      }
    }
  }

  function handleOverwrite() {
    localStorage.setItem("queued-projects", JSON.stringify({ [uniqueId]: [] }));
    handleQueue();
  }

  async function startClassification() {
    try {
      const queued = JSON.parse(localStorage.getItem("queued-projects"));
      const { status } = await v2.post(
        `/domoreports/${uniqueId}/automation/defects/`,
        queued[uniqueId]
      );
      if (status === 201) {
        handleQueue();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={`defect-review-card ${borderClass}`}>
      <QueuedProjectsModal
        show={showConfirmModal}
        close={() => {
          setShowConfirmModal(false);
          setLoading(false);
        }}
        handleOverwrite={handleOverwrite}
        handleSubmit={startClassification}
      />

      <InlineConfirmModal
        title="Delete Defect"
        subtext="Are you sure you want to delete this project?"
        action="Delete"
        variant="danger"
        show={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        handleSubmit={handleDelete}
      />

      <div className="input-wrapper">
        <textarea
          value={defectText}
          onChange={(e) => formatText(e.target.value)}
          rows={2}
        />
      </div>

      <div className={`review-card-footer ${loading ? "loading" : ""}`}>
        <div>
          {isQueued ? (
            <span className={`text-success ${loading ? "text-pressing" : ""}`}>
              {loading ? "Updating" : "Queued"}
            </span>
          ) : (
            <span className={`text-mdblue ${loading ? "text-pressing" : ""}`}>
              {loading ? "Updating" : "Created"}
            </span>
          )}
        </div>

        <div className="review-actions">
          <button className="text-danger" onClick={() => handleDelete()}>
            <i className="fa fa-trash" /> Delete
          </button>
          <button
            className="text-primary"
            onClick={handleQueue}
            disabled={disabled}
          >
            <i className="fa fa-refresh" /> {isQueued ? "Update" : "Re-queue"}
          </button>

          {!isQueued && (
            <input
              type="checkbox"
              id={project.domoreport_project_log_id}
              onClick={handleCheck}
              defaultChecked={defaultChecked}
            />
          )}
        </div>
      </div>
    </div>
  );
}
