import React, { useContext, useState } from "react";
import axios from "../../../services/axios";
import AdminNotesModal from "../../../ui/AdminNotesModal";
import { withRouter } from "react-router-dom";
import { v2 } from "../../../services/axios";
import moment from "moment";
import RoleContext from "../../../contexts/RoleContext";

function ReportTableRow({
  getDomoreports,
  report,
  loading,
  setLoading,
  setDownloading,
  queuedReportKey,
  mdPeople,
  history,
}) {
  const majordomoUrl = process.env.REACT_APP_MAJORDOMO_URL;
  const [showAdminNotesModal, setShowAdminNotesModal] = useState(false);
  const { role } = useContext(RoleContext);

  let status = report.status;
  if (status === "ON_HOLD") {
    status = "ON HOLD";
  }

  let address = "Not set yet";
  if (report != null && report.address) {
    const {
      PropertyAddressFull,
      PropertyAddressCity,
      PropertyAddressState,
      PropertyAddressZIP,
    } = report.address;
    address = `${PropertyAddressFull}, ${PropertyAddressCity}, ${PropertyAddressState} ${PropertyAddressZIP}`;
  }

  let hasQueued = false;
  if (report.unique_id === queuedReportKey) {
    hasQueued = true;
  }

  async function assignAnalyst(e, reportID) {
    e.preventDefault();
    setLoading(true);
    let data = {};

    if (e.target.value) {
      const person = mdPeople.filter(
        (mdPerson) =>
          mdPerson.majordomo_people_ref_id === parseInt(e.target.value)
      );
      data = { analystId: person[0] ? person[0].user_log_id : null };
    }

    try {
      const { status } = await axios.put(
        `/domoreport/${reportID}/assign-analyst`,
        data
      );
      if (status === 204) {
        getDomoreports();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleTableClick(e, report) {
    let parent = e.target.closest("td").classList[0];

    if (
      parent !== "view-domoreport-link" &&
      parent !== "view-customer-link" &&
      parent !== "md-table-actions" &&
      parent !== "md-people-select-wrapper"
    ) {
      if (report.property_log_id) {
        history.push(
          `/diy/report/${report.domoreport_log_id}/property/${report.property_log_id}`
        );
      } else {
        history.push(`/report/${report.domoreport_log_id}`);
      }
    }
  }

  async function downloadHomeInspection(report) {
    setDownloading(true);
    try {
      const inspectionData = await axios.get(
        `/domoreport/inspectionFile/${report.domoreport_log_id}`,
        { responseType: "blob" }
      );

      if (inspectionData.data) {
        const url = window.URL.createObjectURL(new Blob([inspectionData.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          "Domoreport_" + report.domoreport_log_id + "_Home_Inspection.pdf"
        );
        document.body.appendChild(link);
        link.click();
      }
      setDownloading(false);
    } catch (error) {
      console.error(error);
      setDownloading(false);
    }
  }

  async function startAutomationService(id) {
    setLoading(true);
    try {
      const { status } = await v2.post(`/domoreports/${id}/automation`);
      if (status === 201) {
        getDomoreports();
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  return (
    <>
      <AdminNotesModal
        getDomoreports={getDomoreports}
        showModal={showAdminNotesModal}
        close={() => setShowAdminNotesModal(false)}
        report={report}
      />
      <tr
        key={report.domoreport_log_id}
        onClick={(e) => handleTableClick(e, report)}
        className={`${hasQueued ? "queued" : ""} md-report-table-row`}
      >
        <td className="view-domoreport-link" style={{ overflow: "hidden" }}>
          <a
            href={`${majordomoUrl}/repair-estimate/${report.unique_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {report.domoreport_log_id}
          </a>
        </td>

        {role === "PUBLISHER" && (
          <td className="md-people-select-wrapper">
            <select
              className={`md-people-select ${
                !report.majordomo_people_ref_id ? "unclaimed" : ""
              }`}
              defaultValue={report.majordomo_people_ref_id || 0}
              onChange={(e) => assignAnalyst(e, report.domoreport_log_id)}
            >
              <option key={0} value={0}>
                Unclaimed
              </option>
              {mdPeople &&
                mdPeople.map((mdPerson) => {
                  return (
                    <option
                      key={mdPerson.majordomo_people_ref_id}
                      value={mdPerson.majordomo_people_ref_id}
                    >
                      {mdPerson.user_first_name} {mdPerson.user_last_name}
                    </option>
                  );
                })}
            </select>
          </td>
        )}

        <td className={`text-center status md-${report.status}`}>{status}</td>
        <td className="no-wrap">
          <p className="m-0">{address}</p>
        </td>
        <td className="view-customer-link no-wrap">
          <a href={`/customer/${report.createdBy.user_log_id}`}>
            {report.createdBy.user_first_name} {report.createdBy.user_last_name}
          </a>
        </td>
        <td className="no-wrap">
          {report.domoreport_log_added
            ? moment(report.domoreport_log_added).format("lll")
            : null}
        </td>

        <td className="md-table-actions">
          <div>
            <button
              className="md-download-report btn btn-primary"
              onClick={() => downloadHomeInspection(report)}
              title="Download Report"
            >
              <i className="fa fa-download" />
            </button>

            {role !== "PUBLISHER" && !report.majordomo_people_ref_id && (
              <button
                className="md-assign-analyst btn btn-warning"
                onClick={(e) => assignAnalyst(e, report.domoreport_log_id)}
                title="Claim Report"
                disabled={loading}
              >
                Claim
              </button>
            )}

            {role === "PUBLISHER" &&
              status !== "IDENTIFYING" &&
              status !== "CLASSIFYING" &&
              report.address &&
              report.address.PropertyAddressFull && (
                <button
                  className="md-publish-explore btn btn-primary"
                  onClick={() => startAutomationService(report.unique_id)}
                  title="Start Automation"
                  disabled={loading}
                >
                  <i className="fa fa-amazon" />
                </button>
              )}

            <div className="position-relative">
              <button
                className="md-download-report btn btn-primary"
                onClick={() => setShowAdminNotesModal(true)}
                title="Download Report"
              >
                <i className="fa fa-sticky-note" />
              </button>
              {report.admin_notes && (
                <div
                  className="position-absolute bg-warning rounded-circle shadow-sm border border-white"
                  style={{ width: 10, height: 10, top: -3, right: -1 }}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}
export default withRouter(ReportTableRow);
