import "./index.scss";
import React from "react";

const keyToTextMap = {
  estimateGuidance: "Estimate guidance",
  projectReminders: "Project reminders",
  bundlingOptions: "Bundling options",
  accountSupport: "Account support",
  helpfulTips: "Helpful tips",
  recommendations: "Recommendations",
  specialOffers: "Special offers",
  reminders: "Reminders",
  announcements: "Feature updates and announcements",
};

function NotificationDisplay({ keyName, notification }) {
  return (
    <div className="">
      {keyToTextMap[keyName]}:{" "}
      {!!notification ? (
        <i className="fa fa-check md-mdgreen-text" />
      ) : (
        <i className="fa fa-close md-danger-text" />
      )}
    </div>
  );
}
export default NotificationDisplay;
