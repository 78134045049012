import "./index.scss";
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../services/axios";

export default function AdminNotesModal({
  getDomoreports,
  showModal,
  close,
  report,
}) {
  const [loading, setLoading] = useState(false);
  const [adminNotes, setAdminNotes] = useState(report.admin_notes);

  async function updateAdminNotes() {
    setLoading(true);
    try {
      const updatedReportResponse = await axios.put(
        `/domoreport/uploader/domoreport/${report.domoreport_log_id}`,
        { admin_notes: adminNotes }
      );
      if (updatedReportResponse.data) {
        await getDomoreports();
        setLoading(false);
        close();
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handleClose() {
    setAdminNotes(report.admin_notes);
    close();
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={handleClose}
      className="md-admin-notes-modal"
    >
      <Modal.Body>
        <Form>
          <label htmlFor="admin_notes">Admin Notes</label>
          <textarea
            name="admin_notes"
            onChange={(e) => setAdminNotes(e.target.value)}
            value={adminNotes}
            rows={8}
          />
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          Close
        </Button>
        <Button variant="primary" onClick={updateAdminNotes} disabled={loading}>
          {loading ? "Saving..." : "Save notes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
