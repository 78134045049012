import "./index.scss";
import React, { useState } from "react";

export default function InputSearch({
  disabled,
  handleChange,
  handleSearch,
  icon,
  label,
  placeholder,
  results,
  toggleModal,
  value,
}) {
  const [showResults, setShowResults] = useState(false);
  const display =
    value && value.company ? value.company + " - " + value.name : value;

  return (
    <div className="md-diy-input-wrapper">
      <img src={icon} className="md-diy-input-icon" alt="" />
      <input
        disabled={disabled || false}
        type="text"
        placeholder={placeholder}
        onChange={(e) => handleSearch(label, e.target.value)}
        onFocus={() => {
          setShowResults(true);
          handleSearch(label, display);
        }}
        onBlur={() => setShowResults(false)}
        value={display || ""}
      />
      <div className={showResults ? "md-diy-search-results" : "d-none"}>
        {results.map((result) => {
          let resultDisplay = "";
          if (result.company && result.name) {
            resultDisplay = result.company + " - " + result.name;
          } else if (!result.company && result.name) {
            resultDisplay = result.name;
          } else if (!result.company && !result.name) {
            resultDisplay = result;
          }

          return (
            <p
              key={result.id}
              onMouseDown={(e) => {
                e.preventDefault();
                handleChange(label, result);
                setShowResults(false);
              }}
            >
              {resultDisplay}
            </p>
          );
        })}
        <div className="md-diy-search-results-footer">
          <a
            href="/"
            onClick={(e) => e.preventDefault()}
            onMouseDown={(e) => toggleModal(e, label)}
          >
            + Add New
          </a>
        </div>
      </div>
    </div>
  );
}
