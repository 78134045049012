import "./index.scss";
import React, { Component } from "react";
import { Button, Container, Form, Table, Alert } from "react-bootstrap";
import SharingModal from "../../ui/SharingModal";
import OrderDetailsModal from "../../ui/OrderDetailsModal";
import { withRouter, Link } from "react-router-dom";
import withFetch from "../../hocs/withFetch";
import withUser from "../../hocs/withUser";
// import ReactQuill from "react-quill";
import EditAffiliationModal from "../../ui/EditAffiliationModal";
import ConfirmModal from "../../ui/ConfirmModal";
import AffiliateDetails from "./AffiliateDetails";
import axios from "../../services/axios";
import NotificationDisplay from "./NotificationDisplay";

export default
@withRouter
@withUser()
@withFetch((props) => ({
  userData: `/admin/user/${props.match.params.id}`,
  chargeData: `/users/charges`,
  userNotesData: `/admin/user/${props.match.params.id}/notes`,
  userReportsData: `/admin/user/${props.match.params.id}/reports`,
  putUser: (data) => ({
    updatedUser: {
      url: `/admin/user/${props.match.params.id}`,
      method: "PUT",
      force: true,
      refresh: true,
      body: JSON.stringify(data),
    },
  }),
  putUserPhoto: (formData) => ({
    updatedUser: {
      url: `/admin/user/${props.match.params.id}`,
      method: "PUT",
      headers: {
        "Content-Type": null,
      },
      force: true,
      refresh: true,
      body: formData,
    },
  }),
  sendResetPasswordEmail: (email) => ({
    sentEmail: {
      url: `/users/resetPassword?email=${email}`,
    },
  }),
  addNewUserNote: (note) => ({
    newUserNote: {
      url: `/admin/user/${props.match.params.id}/note`,
      method: "POST",
      force: true,
      refresh: true,
      body: JSON.stringify({ note: note }),
      andThen: (res) => ({
        userNotesData: {
          url: `/admin/user/${props.match.params.id}/notes`,
          force: true,
          refresh: true,
        },
      }),
    },
  }),
  createAffiliation: (name) => ({
    createdAffiliation: {
      url: `/affiliations`,
      method: "POST",
      force: true,
      refresh: true,
      body: JSON.stringify({ name }),
      andThen: (res) => ({
        affiliationsData: {
          url: `/affiliations`,
          force: true,
          refresh: true,
        },
      }),
    },
  }),
  deleteAffiliation: (id) => ({
    deletedAffiliation: {
      url: `/affiliations/${id}`,
      method: "DELETE",
      force: true,
      refresh: true,
      andThen: (res) => ({
        affiliationsData: {
          url: `/affiliations`,
          force: true,
          refresh: true,
        },
      }),
    },
  }),
  updateAffiliation: (affiliation) => ({
    updatedAffiliation: {
      url: `/affiliations/${affiliation.id}`,
      method: "PUT",
      body: JSON.stringify({ name: affiliation.name }),
      force: true,
      refresh: true,
    },
  }),
  getAffiliationsData: () => ({
    affiliationsData: {
      url: `/affiliations`,
      force: true,
      refresh: true,
    },
  }),
}))
class EditCustomerComponent extends Component {
  state = {
    affiliation: { id: null, name: "" },
    allAffiliations: [],
    body: null,
    showSharingModal: false,
    showOrderDetailsModal: false,
    report_id: null,
    charge: null,
    user_first_name: null,
    user_last_name: null,
    is_partner: null,
    email: null,
    phone: null,
    address: null,
    showConfirmModal: false,
    showEditAffiliationModal: false,
    showReports: true,
    showScores: true,
    partner: null,
    domoreports: {},
  };

  componentDidMount() {
    this.props.getAffiliationsData();
  }

  componentDidUpdate() {
    const { is_partner, affiliation, domoreports } = this.state;
    const { affiliationsData, createdAffiliation, userData, userReportsData } =
      this.props;

    if (userData && !userData.pending && userData.value) {
      if (is_partner === null) {
        this.setState({ is_partner: userData.value.is_partner });
      }

      if (
        userData.value.affiliation &&
        affiliation &&
        !affiliation.id &&
        !affiliation.name
      ) {
        this.setState({ affiliation: userData.value.affiliation });
      }
    }

    if (
      createdAffiliation &&
      !createdAffiliation.pending &&
      createdAffiliation.value &&
      affiliationsData &&
      !affiliationsData.pending &&
      affiliationsData.value &&
      affiliation == null
    ) {
      const { id, name } = createdAffiliation.value.affiliation;
      this.setState({ affiliation: { id: id, name: name } });
    }

    if (
      userReportsData &&
      !userReportsData.pending &&
      userReportsData.value &&
      !Object.keys(domoreports).length
    ) {
      const { archived, reports, unpublished } = userReportsData.value;
      this.getDomoreports([...archived, ...reports, ...unpublished]);
    }
  }

  toggleSharingModal = (reportID, address) => {
    this.setState({
      showSharingModal: !this.state.showSharingModal,
      report_id: reportID,
      address: address,
    });
  };

  toggleOrderDetailsModal = (reportID, charge) => {
    this.setState({
      showOrderDetailsModal: !this.state.showOrderDetailsModal,
      report_id: reportID,
      charge: charge,
    });
  };

  updateUser = (e, user) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      email,
      phone,
      user_photo,
      is_partner,
      affiliation,
    } = this.state;

    var formData = new FormData();
    if (user_photo) {
      formData.append("user_photo", user_photo);
      this.props.putUserPhoto(formData);
    }

    let data = {
      user_first_name: first_name || user.user_first_name,
      user_last_name: last_name || user.user_last_name,
      user_email: email || user.user_email,
      phone_number: phone || user.user_phone,
      is_partner: is_partner || user.is_partner,
      affiliation_id: affiliation.id || user.affiliation,
    };

    if (data) {
      this.props.putUser(data);
    }
  };

  _validateValue = (value) => {
    if (value === "" || value === " " || value === 0) {
      return null;
    } else {
      return value;
    }
  };

  _handleFormChange = (key, value) => {
    this.setState({ [key]: this._validateValue(value) || null });
  };

  _hideModal = () => {
    this.setState({
      showSharingModal: false,
      showOrderDetailsModal: false,
    });
  };

  _renderAlert = (variant, message) => {
    return (
      <Alert
        variant={variant}
        onClose={() => {
          this.setState({ showMessage: false });
        }}
        className="md-alert"
      >
        <p>{message}</p>
      </Alert>
    );
  };

  // Handle Inspector/Agent search
  handleSearch = (key, value) => {
    const { affiliation } = this.state;
    const { affiliationsData } = this.props;
    let results = [];

    if (value == null) {
      value = "";
    }

    if (affiliationsData.value) {
      affiliationsData.value.affiliations.map((affiliate) => {
        if (
          affiliate.name &&
          affiliate.name.toUpperCase().includes(value.toUpperCase())
        ) {
          let result = {
            id: affiliate.id,
            name: affiliate.name,
          };
          results.push(result);
        }
      });

      this.setState({
        affiliation: { id: affiliation.id, name: value },
        allAffiliations: results,
      });
    }
  };

  getDomoreports = async (reports) => {
    let allReports = {};
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];
      const { data } = await axios.get(
        `/domoreport/uploader/domoreport/${report.domoreport_log_id}`
      );

      allReports[report.domoreport_log_id] = data.unique_id;
    }
    this.setState({ domoreports: allReports });
  };

  render() {
    const { userData, chargeData, userNotesData, userReportsData } = this.props;

    let userReports = [];
    let userScores = [];
    if (userReportsData && !userReportsData.pending && userReportsData.value) {
      userReportsData.value.reports.map((report) => {
        userReports.push(report);
      });
      userReportsData.value.unpublished.map((report) => {
        userReports.push(report);
      });
      userReportsData.value.domoscores.map((score) => {
        userScores.push(score);
      });
      const compare = (a, b) => {
        let sortA = "";
        let sortB = "";
        let comparison = 0;

        if (a.domoreport_log_id) {
          sortA = a.domoreport_log_id;
          sortB = b.domoreport_log_id;
        } else if (a.property_log_id) {
          sortA = a.property_log_id;
          sortB = b.property_log_id;
        }

        if (sortA < sortB) {
          comparison = 1;
        } else if (sortA > sortB) {
          comparison = -1;
        }
        return comparison;
      };
      userReports.sort(compare);
      userScores.sort(compare);
    }

    return userData &&
      !userData.pending &&
      userData.value &&
      chargeData &&
      !chargeData.pending &&
      userNotesData &&
      !userNotesData.pending
      ? this._renderAvailableState(
          userData.value,
          chargeData.value,
          userNotesData,
          userReports,
          userScores
        )
      : this._renderEmptyState();
  }

  _renderEmptyState = () => {
    return (
      <div className="md-scroll-wrapper">
        <Container>
          <Link className="mt-3" to="/customers">
            Go Back
          </Link>
          <h2 className="mt-3">Edit Customer</h2>
          <p>empty state</p>
        </Container>
      </div>
    );
  };

  toggleShowReports = (e) => {
    e.preventDefault();
    const { showReports } = this.state;
    this.setState({ showReports: !showReports });
  };

  toggleShowScores = (e) => {
    e.preventDefault();
    const { showScores } = this.state;
    this.setState({ showScores: !showScores });
  };

  toggleModal = () => {
    const { showConfirmModal } = this.state;
    this.setState({ showConfirmModal: !showConfirmModal });
  };

  _renderAvailableState = (
    content,
    chargeData,
    userNotesData,
    userReports,
    userScores
  ) => {
    const {
      is_partner,
      report_id,
      address,
      charge,
      showSharingModal,
      showOrderDetailsModal,
      affiliation,
      allAffiliations,
      showConfirmModal,
      showResults,
      showEditAffiliationModal,
      showReports,
      showScores,
    } = this.state;
    const { updatedUser, newUserNote, sentEmail } = this.props;

    return (
      <div className="md-scroll-wrapper">
        <Container className="md-edit-customer-page">
          {/* {showEditAffiliationModal && (
            <EditAffiliationModal
              affiliation={affiliation}
              close={() => this.setState({ showEditAffiliationModal: false })}
              getAffiliationsData={this.props.getAffiliationsData}
              handleChange={this._handleFormChange}
            />
          )} */}

          <ConfirmModal
            title="Create Affiliation"
            subtext={`Are you sure you want to create a new affiliation for "${
              affiliation ? affiliation.name : ""
            }"`}
            action="Create"
            variant="success"
            show={showConfirmModal}
            close={this.toggleModal}
            handleSubmit={() => {
              this.setState({ affiliation: null, showConfirmModal: false });
              this.props.createAffiliation(affiliation.name);
            }}
          />

          {showSharingModal && (
            <SharingModal
              reportID={report_id}
              hideModal={this._hideModal}
              token={this.props.user.token}
              address={address}
            />
          )}

          {showOrderDetailsModal && (
            <OrderDetailsModal
              reportID={report_id}
              hideModal={this._hideModal}
              user={content}
              charge={charge}
              token={this.props.user.token}
            />
          )}

          <Container>
            {updatedUser &&
              !updatedUser.pending &&
              updatedUser.value &&
              updatedUser.value.success &&
              this._renderAlert("success", updatedUser.value.message)}
            {updatedUser &&
              !updatedUser.pending &&
              updatedUser.value &&
              updatedUser.value.error &&
              this._renderAlert("danger", "Something went wrong")}
            {newUserNote &&
              !newUserNote.pending &&
              newUserNote.value &&
              newUserNote.value.success &&
              this._renderAlert("success", "New note added")}
            {sentEmail &&
              !sentEmail.pending &&
              sentEmail.value.success &&
              this._renderAlert(
                "success",
                `An email to reset account password has been sent to ${content.user_email}`
              )}
            <Link className="mt-3" to="/customers">
              Go Back
            </Link>

            <h2 className="mt-3">Edit Customer</h2>
            <hr width="100%" />

            <h4>Customer Info</h4>
            <div className="row">
              <Form.Group
                className={"col-12"}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Form.Label>Profile Photo</Form.Label>
                {content.user_profile_pic ? (
                  <img src={content.user_profile_pic} width="40%" />
                ) : (
                  <div></div>
                )}
                <Form.Control
                  type="file"
                  onChange={(e) =>
                    this._handleFormChange("user_photo", e.target.files[0])
                  }
                />
              </Form.Group>

              <Form.Group className={"col-6 mx-auto"}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    this._handleFormChange("first_name", e.target.value)
                  }
                  defaultValue={content.user_first_name}
                />
              </Form.Group>

              <Form.Group className={"col-6 mx-auto"}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    this._handleFormChange("last_name", e.target.value)
                  }
                  defaultValue={content.user_last_name}
                />
              </Form.Group>

              <Form.Group className={"col-6 mx-auto"}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    this._handleFormChange("email", e.target.value)
                  }
                  defaultValue={content.user_email}
                />
              </Form.Group>

              <Form.Group className={"col-6 mx-auto"}>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    this._handleFormChange("phone", e.target.value)
                  }
                  defaultValue={content.phone_number}
                />
              </Form.Group>

              <Form.Group className={"col-6 d-flex align-items-center mt-4"}>
                <div>
                  <Link
                    to="#"
                    style={{
                      color: "#ff0000",
                      width: "60%",
                    }}
                    onClick={(e) => {
                      this.props.sendResetPasswordEmail(content.user_email);
                    }}
                  >
                    {" "}
                    Send Password Reset Link
                  </Link>
                </div>
              </Form.Group>

              <hr width="100%" />

              <Form.Group className="col-12 my-4">
                <h4>Notification Preferences</h4>
              </Form.Group>
              <Form.Group className="col-4">
                <p>
                  <b>Get notifications about (call)...</b>
                </p>
                <NotificationDisplay
                  keyName="estimateGuidance"
                  notification={
                    content.notifications
                      ? content.notifications.estimateGuidance
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="projectReminders"
                  notification={
                    content.notifications
                      ? content.notifications.projectReminders
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="bundlingOptions"
                  notification={
                    content.notifications
                      ? content.notifications.bundlingOptions
                      : true
                  }
                />
              </Form.Group>

              <Form.Group className="col-4">
                <p>
                  <b>Text me about...</b>
                </p>
                <NotificationDisplay
                  keyName="accountSupport"
                  notification={
                    content.notifications
                      ? content.notifications.accountSupport
                      : true
                  }
                />
              </Form.Group>

              <Form.Group className="col-4">
                <p>
                  <b>Email me about...</b>
                </p>
                <NotificationDisplay
                  keyName="helpfulTips"
                  notification={
                    content.notifications
                      ? content.notifications.helpfulTips
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="recommendations"
                  notification={
                    content.notifications
                      ? content.notifications.recommendations
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="specialOffers"
                  notification={
                    content.notifications
                      ? content.notifications.specialOffers
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="reminders"
                  notification={
                    content.notifications
                      ? content.notifications.reminders
                      : true
                  }
                />
                <NotificationDisplay
                  keyName="announcements"
                  notification={
                    content.notifications
                      ? content.notifications.announcements
                      : true
                  }
                />
              </Form.Group>

              {/* <Form.Group className={"col-6"}>
                <Form.Label>
                  Partner{" "}
                  {affiliation && affiliation.id && (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ showEditAffiliationModal: true });
                      }}
                    >
                      Edit
                    </a>
                  )}
                </Form.Label>
                <div className="md-diy-input-wrapper">
                  <input
                    type="text"
                    placeholder="Search Affiliations"
                    onChange={(e) =>
                      this.handleSearch("affiliation", e.target.value)
                    }
                    onFocus={() => {
                      this.setState({ showResults: true });
                      this.handleSearch(
                        "affiliation",
                        affiliation ? affiliation.name : ""
                      );
                    }}
                    onBlur={() => this.setState({ showResults: false })}
                    value={affiliation ? affiliation.name : ""}
                  />
                  <div
                    className={showResults ? "md-diy-search-results" : "d-none"}
                  >
                    {allAffiliations.map((affiliate) => {
                      return (
                        <p
                          key={affiliate.id}
                          onMouseDown={(e) => {
                            this._handleFormChange("affiliation", affiliate);
                            this.setState({ showResults: false });
                          }}
                        >
                          {affiliate.name}
                        </p>
                      );
                    })}
                    <div className="md-diy-search-results-footer">
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        onMouseDown={(e) => this.toggleModal(e, "affiliation")}
                      >
                        + Add New
                      </a>
                    </div>
                  </div>
                </div>
              </Form.Group> */}

              <hr width="100%" />

              {/* {content.partnerId ? (
                <div className="col-12">
                  <AffiliateDetails user={content} />
                </div>
              ) : (
                <div className="col-12 text-danger">
                  <p>This user has not joined the Affiliate Program yet</p>
                </div>
              )} */}

              <div className="col-12">
                <h4>
                  Domoreports ({userReports && userReports.length} total){" "}
                  <a
                    href="#"
                    onClick={this.toggleShowReports}
                    className="md-black-text"
                  >
                    {showReports ? (
                      <i className="fa fa-caret-up" />
                    ) : (
                      <i className="fa fa-caret-down" />
                    )}
                  </a>
                </h4>
                {showReports && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Address</th>
                        <th>Permission Level</th>
                        <th>Viewed At</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userReports.map((report, index) => {
                        let charge = [];

                        if (chargeData && chargeData.charges) {
                          chargeData.charges.filter((order) => {
                            return (
                              order.domoreport_log_id ==
                              report.domoreport_log_id
                            );
                          });
                        }

                        let address = report.address
                          ? report.address +
                            ", " +
                            report.city +
                            ", " +
                            report.state
                          : "Domoreport #" + (userReports.length - index);

                        return (
                          <tr key={report.domoreport_log_id}>
                            <td>
                              <Link
                                to={`/diy/report/${report.domoreport_log_id}/property/${report.property_log_id}`}
                              >
                                {report.domoreport_log_id}
                              </Link>
                            </td>
                            <td>{address}</td>
                            <td>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.toggleSharingModal(
                                    report.domoreport_log_id,
                                    address
                                  );
                                }}
                              >
                                {" "}
                                {report.permissions}
                              </Link>
                            </td>
                            <td>
                              {report.viewed_at
                                ? new Date(report.viewed_at).toLocaleString()
                                : ""}
                            </td>
                            <td>
                              <a
                                href={
                                  process.env.REACT_APP_BUILD_ENV ===
                                  "production"
                                    ? `https://repair.thumbtack.com/repair-estimate/${
                                        this.state.domoreports[
                                          report.domoreport_log_id
                                        ]
                                      }`
                                    : `https://dev.repair.thumbtack.com/repair-estimate/${
                                        this.state.domoreports[
                                          report.domoreport_log_id
                                        ]
                                      }`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Domoreport
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>

              {/* <div className="col-12 mt-4 mb-4">
                <h4>
                  Domoscores ({userScores && userScores.length} total){" "}
                  <a
                    href="#"
                    onClick={this.toggleShowScores}
                    className="md-black-text"
                  >
                    {showScores ? (
                      <i className="fa fa-caret-up" />
                    ) : (
                      <i className="fa fa-caret-down" />
                    )}
                  </a>
                </h4>
                {showScores && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Address</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userScores.map((domoscore, index) => {
                        let address = domoscore.address
                          ? domoscore.address +
                            ", " +
                            domoscore.city +
                            ", " +
                            domoscore.state
                          : "Domoscore #" + (userScores.length - index);

                        return (
                          <tr key={domoscore.property_log_id}>
                            <td>
                              <Link
                                to={`/report/null/home/${domoscore.property_log_id}`}
                              >
                                {domoscore.property_log_id}
                              </Link>
                            </td>
                            <td>{address}</td>
                            <td>
                              <a
                                href={
                                  process.env.REACT_APP_BUILD_ENV ==
                                  "production"
                                    ? `https://majordomo.com/domoscore?report=${domoscore.domoreport_log_id}&property=${domoscore.property_log_id}`
                                    : `https://dev.majordomo.com/domoscore?report=${domoscore.domoreport_log_id}&property=${domoscore.property_log_id}`
                                }
                                target="_blank"
                              >
                                {" "}
                                View Domoscore
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div> */}

              {/* <hr width="100%" />
              <h4>Notes</h4>
              <div className="col-12 md-notes-container">
                {userNotesData.value &&
                  userNotesData.value.notes.map((note) => {
                    return (
                      <div className="md-note" key={note.addedAt}>
                        <div
                          dangerouslySetInnerHTML={{ __html: note.note }}
                        ></div>
                        <p>
                          <b>
                            {note.author.first_name} {note.author.last_name}
                          </b>{" "}
                          on {note.addedAt}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <ReactQuill
                onChange={(evt) => {
                  this._handleFormChange("note", evt);
                }}
                className="col-12 mx-auto"
                style={{ marginBottom: 50 }}
              />
              <Button
                variant="primary"
                size="lg"
                block
                style={{ marginTop: 20, fontSize: 16 }}
                onClick={() => {
                  this.props.addNewUserNote(this.state.note);
                }}
                className="col-2 ml-auto"
              >
                {" "}
                Save Note
              </Button> */}

              <hr width="100%" />
              <Button
                variant="primary"
                size="lg"
                block
                style={{ marginTop: 20 }}
                onClick={(e) => this.updateUser(e, content)}
                className="col-12 mx-auto"
              >
                {" "}
                Update
              </Button>
              <Button
                variant="danger"
                size="lg"
                block
                style={{ marginTop: 20 }}
                onClick={() => console.log("Delete User")}
                className="col-12 mx-auto"
              >
                {" "}
                Delete
              </Button>
              <Button
                variant="secondary"
                size="lg"
                block
                style={{ marginTop: 20 }}
                onClick={() => this.props.history.push("/customers")}
                className="col-12 mx-auto mb-5"
              >
                {" "}
                Cancel
              </Button>
            </div>
          </Container>
        </Container>
      </div>
    );
  };
}
