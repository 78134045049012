import React, { Component } from "react";
import { Form, Container, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { makeLabel, styleBorder, makePlaceholder } from ".";
import { updateMetaMeasure } from "../../actions";

class MetaMeasureList extends Component {
  render() {
    let { newMetaMeasure } = this.props;
    return (
      <Container style={{ marginBottom: 20 }}>
        {this.props.metaMeasures.map((measure, i) => {
          return (
            <div>
              <Col>
                <Form.Label>Measure</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"update a measure"}
                  value={measure.measure}
                  onChange={(evt) => {
                    this.props.updateMetaMeasure(
                      i,
                      evt.target.value,
                      measure.value
                    ); //handleSpecialChange.measureChange(evt.target.value, null, measure.meta_measure_id, true);
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"update a value"}
                  value={measure.value}
                  onChange={(evt) => {
                    this.props.updateMetaMeasure(
                      i,
                      measure.measure,
                      evt.target.value
                    ); //handleSpecialChange.measureChange(null, evt.target.value, measure.meta_measure_id, true);
                  }}
                />
              </Col>
            </div>
          );
        })}

        <div>
          <Col>
            <Form.Label>Measure</Form.Label>
            <Form.Control
              type="text"
              placeholder={"Add a new measure"}
              value={newMetaMeasure.measure}
              onChange={(evt) => {
                this.props.updateMetaMeasure(
                  null,
                  evt.target.value,
                  newMetaMeasure.value
                ); //handleSpecialChange.measureChange(evt.target.value, null, null, true);
              }}
            />
          </Col>
          <Col>
            <Form.Label>Value</Form.Label>
            <Form.Control
              type="text"
              placeholder={"Add a new value"}
              value={newMetaMeasure.value}
              onChange={(evt) => {
                this.props.updateMetaMeasure(
                  null,
                  newMetaMeasure.measure,
                  evt.target.value
                ); //handleSpecialChange.measureChange(null, evt.target.value, null, true);
              }}
            />
          </Col>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  metaMeasures: state.reports.metaMeasures,
  newMetaMeasure: state.reports.newMetaMeasure,
});

export default connect(mapStateToProps, { updateMetaMeasure })(MetaMeasureList);
