import React, { Component, useEffect, useState } from "react";
import { Modal, Button, Form, Card, CardImg } from "react-bootstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import MDImage from "../../components/MDImage";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

export default class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = (photos) => {
      this.setState({ photos });
    };
    this.state = {
      doc_log_id: null,
      photos: [],
      title: "",
      description: "",
      photoType: false,
      hover: false,
    };
  }

  thumbnails = (photos) =>
    photos.map((photo) => (
      <Card style={thumb} key={photo.name}>
        <CardImg src={URL.createObjectURL(photo)} />
        <span style={{ fontSize: "0.7rem" }}>{photo.name}</span>
      </Card>
    ));

  componentDidMount() {
    if (!this.props.upload && this.props.photo_info) {
      this.setState({
        title: this.props.photo_info.doc_title,
        description: this.props.photo_info.doc_description,
        doc_log_id: this.props.photo_info.doc_log_id,
      });
    }
  }

  render() {
    const photos = this.state.photos.map((photo) => (
      <li key={photo.name}>
        {photo.name} - {photo.size} bytes
      </li>
    ));
    let obj = this.props.obj ? this.props.obj : {};
    obj = { ...obj, ...this.props.dropdownValues };
    let buttonDisabled = false;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = key in this.props.state ? this.props.state[key] : obj[key];
        if (this.props.keysToIgnore) {
          if (!(key in this.props.keysToIgnore)) {
            buttonDisabled =
              buttonDisabled ||
              obj[key] === "" ||
              obj[key] === "null" ||
              obj[key] === null;
            if (obj[key] === "" || obj[key] === "null" || obj[key] === null) {
              console.log(key, obj[key]);
            }
          }
        }
      }
    }
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        onHide={this.props.close}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.photos && this.state.photos.length < 2 ? (
            <React.Fragment>
              <select
                className="form-control my-2"
                onChange={(evt) =>
                  this.setState({ photoType: evt.target.value })
                }
              >
                <option value={false}>Document</option>
                <option value={true}>Photo</option>
              </select>
              <input
                className="form-control my-2"
                type="text"
                value={this.state.title}
                name="title"
                id="title"
                placeholder="Title"
                onChange={(evt) => this.setState({ title: evt.target.value })}
              />
              <textarea
                className="form-control my-2"
                value={this.state.description}
                name="description"
                placeholder="description"
                onChange={(evt) =>
                  this.setState({ description: evt.target.value })
                }
              />
            </React.Fragment>
          ) : null}

          {this.state.photos && this.state.photos.length ? (
            <h5>Thumbnails</h5>
          ) : null}
          {this.thumbnails(this.state.photos)}
          <Form>
            {this.props.upload ? (
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <div style={{ cursor: "pointer" }}>
                        Drag 'n' drop some photos here, or click to select
                        photos
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <Card>
                <MDImage
                  className="report-detail-img"
                  sourceURL={`http://majordomostagingapi.herokuapp.com/api/1/domoreport/${this.props.photo_report_id}/domoreportDocument/${this.props.photo_doc_log_id}`}
                  loadedPhotoData={this.props.photo}
                  token={this.props.photo_user_token}
                />
              </Card>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.close}>
            Close
          </Button>
          {!this.props.upload ? (
            <Button
              variant="danger"
              onClick={() => this.props.delete(this.props.photo_doc_log_id)}
            >
              delete
            </Button>
          ) : null}
          {this.props.upload ? (
            <Button
              variant="primary"
              onClick={() => {
                this.props.submit(
                  "defaults" in this.props ? this.props.defaults : null,
                  this.state
                );
              }}
              disabled={this.props.submitting || buttonDisabled}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                this.props.update(
                  "defaults" in this.props ? this.props.defaults : null,
                  this.state
                );
              }}
              disabled={this.props.submitting || buttonDisabled}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
