import React, { Component } from "react";
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  ListGroup,
  Table,
} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import ReportNav from "./reportNav";
import withFetch from "../../hocs/withFetch";
import withUser from "../../hocs/withUser";
import FormByKey from "../../ui/FormByKey/form";
import { _returnFields } from "../../ui/FormByKey/reportFields";
import PropertyAddressSearch from "../../ui/PropertyAddressSearch";
import WarningModal from "../../ui/WarningModal";
import { connect } from "react-redux";
import SharingModal from "../../ui/SharingModal";
import {
  getAllMDPeople,
  getAllBrokers,
  getOneReport,
  updateReport,
  updateOneReport,
  getReportFields,
  getAllInspectors,
  getMetaMeasures,
} from "../../actions";

export const keysToIgnore = {
  domoreport_log_id: "domoreport_log_id",
  inspection_id: "inspection_id",
  domoreport_log_added: "domoreport_log_added",
  domoreport_log_last_modified: "domoreport_log_last_modified",
  creator_user_id: "creator_user_id",
  domoreport_published: "domoreport_published",
  domo_address_id: "domo_address_id",
  property_log_id: "property_log_id",
  is_archived: "is_archived",
  explore_content_published: "explore_content_published",
  metaMeasures: "metaMeasures",
  primary_property_document_id: "primary_property_document_id",
  explore_published_at: "explore published at",
  report_published_at: "report_published_at",
};

export const keysToDisable = {
  explore_content_published: "explore_content_published",
};

const mapStateToProps = (state) => ({
  mdPeople: state.mdpeople.people,
  brokers: state.brokers.brokers,
  report: state.reports.report,
  reportFields: state.reports.fields,
  fieldsHash: state.reports.fieldsHash,
  inspectors: state.inspectors.inspectors,
  reportFetching: state.reports.oneFetching,
  updatingReport: state.reports.updatingReport,
  measuresFetching: state.reports.measuresFetching,
  updatingMeasure: state.reports.updatingMeasure,
  fieldsFetched: state.reports.fieldsFetched,
  error: state.reports.error,
});

export default
@withRouter
@withUser()
@connect(mapStateToProps, {
  getAllMDPeople,
  getAllBrokers,
  getAllInspectors,
  getOneReport,
  updateReport,
  updateOneReport,
  getReportFields,
  getMetaMeasures,
})
@withFetch((props) => ({
  publishReport: () => ({
    publishedReport: {
      url: `/domoreport/uploader/publishDomoreport/${props.match.params.report_id}`,
      method: "PUT",
      force: true,
      refreshing: true,
    },
  }),
  deleteReport: () => ({
    deletedReport: {
      url: `/domoreport/uploader/domoreport/${props.match.params.report_id}`,
      method: "DELETE",
    },
  }),
  publishExploreContent: () => ({
    updatedExploreContent: {
      url: `/domoreport/uploader/emailExploreContentFor/${props.match.params.report_id}`,
      method: "PUT",
      force: true,
      refreshing: true,
    },
  }),
}))
class EditReportComponent extends Component {
  state = {
    fromServer: false,
    formSubmission: false,
    alertMessage: "",
    alertBG: "",
    createWarningOpen: false,
    forceRefresh: false,
    checkFetch: false,
    waitForAdd: false,
    waitForDelete: false,
    readyToPublish: false,
    showSharingModal: false,
  };

  componentDidMount() {
    this.props.getAllMDPeople();
    this.props.getAllBrokers();
    this.props.getAllInspectors();
    this.props.getOneReport(this.props.match.params.report_id);
    this.props.getReportFields();
    this.props.getMetaMeasures(this.props.match.params.report_id);
  }

  componentDidUpdate() {
    let exploreContentUpdate =
      "updatedExploreContent" in this.props &&
      !this.props.updatedExploreContent.pending &&
      !this.props.updatedExploreContent.refreshing &&
      this.state.explore_content_published === 0 &&
      !this.state.checkFetch;
    let addMeasure =
      "addedMetaMeasure" in this.props &&
      !this.props.addedMetaMeasure.pending &&
      !this.props.addedMetaMeasure.refreshing &&
      this.state.waitForAdd;
    let deleteMeasure =
      "deletedMetaMeasure" in this.props &&
      !this.props.deletedMetaMeasure.pending &&
      !this.props.deletedMetaMeasure.refreshing &&
      this.state.waitForDelete;

    if (exploreContentUpdate) {
      this.props.fetchReport();
      this.setState({ checkFetch: true });
    }

    if (addMeasure) {
      this.props.refetchMetaMeasures();
      this.setState({ checkFetch: true, waitForAdd: false });
    }

    if (deleteMeasure) {
      this.props.refetchMetaMeasures();
      this.setState({ checkFetch: true, waitForDelete: false });
    }

    if (
      "fetchedReport" in this.props &&
      !this.props.fetchedReport.pending &&
      !this.props.fetchedReport.refreshing &&
      this.state.checkFetch
    ) {
      this.setState({
        explore_content_published:
          this.props.fetchedReport.value.explore_content_published,
        checkFetch: false,
      });
    }

    if (
      "fetchedMetaMeasures" in this.props &&
      !this.props.fetchedMetaMeasures.pending &&
      !this.props.fetchedMetaMeasures.refreshing &&
      this.state.checkFetch
    ) {
      let tempState = this.state;
      tempState.metaMeasureMemo = {
        newMetaMeasure: { metaMeasure: null, metaValue: null },
      };
      this.props.fetchedMetaMeasures.value.measures.map((measure) => {
        let measureObj = this._createMeasureObj(
          measure.measure,
          measure.value,
          measure.meta_measure_id,
          false
        );
        tempState.metaMeasureMemo = {
          ...tempState.metaMeasureMemo,
          ...measureObj,
        };
      });
      this.setState(
        {
          ...tempState,
          metaMeasures: this.props.fetchedMetaMeasures.value.measures,
          checkFetch: false,
          tracer: true,
        },
        () => {}
      );
    }
    if (this.state.tracer) {
      this.setState({ tracer: !this.state.tracer });
    }
  }

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  _toggleSharing = () => {
    this.setState({
      showSharingModal: !this.state.showSharingModal,
    });
  };

  _handleChange = (key, value) => {
    this.props.updateReport(key, value);
  };

  _handleBoolChange = (key, value) => {
    if (key === "domoreport_published" && !this.state.readyToPublish) {
      this.setState({ readyToPublish: value });
      this.props.updateReport(key, value);
    } else {
      this.props.updateReport(key, value);
    }
  };

  _handleDateChange = (key, value, component, initialString) => {
    let dateString = initialString;
    if (component === "yyyy") {
      dateString =
        String(Math.abs(value)).padStart(4, 0).substr(0, 4) +
        dateString.substr(4);
    } else if (component === "mm") {
      dateString =
        dateString.substr(0, 5) +
        String(Math.abs(value)).padStart(2, 0).substr(0, 2) +
        dateString.substr(7);
    } else if (component === "dd") {
      if (Number(value) > 31) {
        value = 31;
      } else if (Number(value) < 1) {
        value = 1;
      }
      dateString =
        dateString.substr(0, 8) +
        String(value).padStart(2, 0).substr(0, 2) +
        dateString.substr(10);
    } else {
    }
    this.props.updateReport(key, dateString);
  };

  _getCurrentDateString = () => {
    let dateStr = ""; //"YYYY-MM-DD"
    let currentDate = new Date();
    dateStr = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, 0)}-${String(currentDate.getDate()).padStart(
      2,
      0
    )}T00:00:00.000Z`;

    return dateStr;
  };

  _toggleWarningModal = () => {
    const { createWarningOpen } = this.state;
    this.setState({ createWarningOpen: !createWarningOpen }, () => {});
  };

  // _createInitialValues = (serverValues, allMdPeople, allBrokers, allInspectors, metaMeasures) => {
  // 	let values = serverValues
  // 	values.inspection_datetime = (values.inspection_datetime != null) ? values.inspection_datetime : this._getCurrentDateString();
  // 	values.inspection_processing_datetime = (values.inspection_processing_datetime != null) ? values.inspection_processing_datetime : this._getCurrentDateString();
  // 	values.inspector_ref_id = ((values.inspector_ref_id != null) ? values.inspector_ref_id : allInspectors.inspectors[0].inspector_ref_id) ;
  // 	values.broker_ref_id = ((values.broker_ref_id != null) ? values.broker_ref_id : allBrokers.brokers[0].broker_ref_id);
  // 	values.majordomo_people_ref_id = ((values.majordomo_people_ref_id != null) ? values.majordomo_people_ref_id : allMdPeople.people[0].majordomo_people_ref_id);
  // 	return values
  // }

  _handleAddressChange = (property_log_id, domo_address_id) => {
    this.setState({ property_log_id, domo_address_id }, () => {
      this._onSubmit();
    });
  };

  handleKeyDown(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === "s") {
      event.preventDefault();
      this._onSubmit();
    }

    // For MAC we can use metaKey to detect cmd key

    if (event.metaKey && charCode === "s") {
      event.preventDefault();
      this._onSubmit();
    }
  }

  _onSubmit = () => {
    if (this.state.readyToPublish) {
      this.props.publishReport();
      this.setState({ readyForUpdate: false });
    }
    this.props.updateOneReport(this.props.match.params.report_id);

    this.setState({ formSubmission: true });
    this.props.history.push(
      `/diy/report/${this.props.report.domoreport_log_id}/property/${this.props.report.property_log_id}`
    );
  };

  _onDelete = () => {
    const { deleteReport } = this.props;
    deleteReport();
    this.goBack();
  };

  _publishExploreContent = () => {
    const { publishExploreContent } = this.props;
    publishExploreContent();
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { createWarningOpen } = this.state;
    const { user } = this.props;

    if (!this.props.fieldsFetched) {
      return (
        <Container>
          <h2>Report:</h2>
          <Link to="/reports">Go Back</Link>
        </Container>
      );
    }

    let value = this.props.report;
    let fields = this.props.reportFields;
    let allInspectors = { inspectors: this.props.inspectors };
    let allBrokers = { brokers: this.props.brokers };
    let allMdPeople = { people: this.props.mdPeople };
    //let { value: measures } = metaMeasures;

    let { fieldsHash } = this.props;

    // if (!this.state.fromServer){
    // 	//value = this._createInitialValues(value, allMdPeople, allBrokers, allInspectors, measures.measures);
    // 	//this.setState({...value, fromServer: !this.state.fromServer})
    // }

    let buttonDisabled = false;
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = key in this.state ? this.state[key] : value[key];
        if (!(key in keysToIgnore) && key in fieldsHash) {
          buttonDisabled =
            buttonDisabled ||
            value[key] === "" ||
            value[key] === "null" ||
            value[key] === null;
        }
      }
    }

    return (
      <div className="md-scroll-wrapper">
        <Container onKeyDown={this.handleKeyDown}>
          {(this.props.updatingMeasure || this.props.updatingReport) && (
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                zIndex: 999,
                position: "fixed",
                left: 0,
                top: 0,
                width: "100vw",
                height: "100vh",
              }}
            ></div>
          )}
          <Row>
            <Col>
              <h2>Report: {value.domoreport_log_id}</h2>
              <Link to="/reports">Go Back</Link>
              {value.property_log_id === null && (
                <PropertyAddressSearch
                  reportId={value.domoreport_log_id}
                  handlePropertyChange={this._handleAddressChange}
                />
              )}
              {value.property_log_id != null && (
                <div>
                  <ReportNav
                    reportId={this.props.match.params.report_id}
                    propertyId={value.property_log_id}
                    token={(user && user.value && user.value.token) || null}
                    report={value}
                  />
                  {!value.explore_content_published && (
                    <Button
                      variant="success"
                      size="lg"
                      block
                      style={{ marginTop: 20 }}
                      onClick={this._publishExploreContent}
                    >
                      Publish Explore Content
                    </Button>
                  )}
                  <FormByKey
                    alertMessage={this.state.alertMessage}
                    alertBG={this.state.alertBG}
                    fields={fields}
                    obj={{
                      ...value,
                      ...allInspectors,
                      ...allBrokers,
                      ...allMdPeople,
                    }}
                    onChange={this._handleChange}
                    submit={this._onSubmit}
                    boolChange={this._handleBoolChange}
                    handleSpecialChange={{ dateChange: this._handleDateChange }}
                    returnFields={_returnFields}
                    disabled={buttonDisabled}
                    fieldsToDisable={keysToDisable}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {this.props.error != null && (
              <Alert variant="danger">
                There was an error: {this.props.error.message}
              </Alert>
            )}
          </Row>
          <Row>
            <Col sm="9"></Col>
            <Col sm="3">
              <Button variant="danger" onClick={this._toggleWarningModal}>
                DELETE
              </Button>
            </Col>
          </Row>
          <Row style={{ height: 25 }}></Row>
          {createWarningOpen && (
            <WarningModal
              close={this._toggleWarningModal}
              delete={this._onDelete}
              titleLable={"Are you sure you want to delete this report?"}
              bodyLable={
                'Although it is not a permanent deletion, if you are doing this by accident, it\'s going to upset Matthew pretty good if he has to go and "undelete" it for you.'
              }
              buttonLable={"Yep, roll the dice on Matthew's mood."}
            />
          )}
        </Container>
      </div>
    );
  }

  _hideModal = () => {
    this.setState({
      showSharingModal: false,
    });
  };
}
