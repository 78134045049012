import "./index.scss";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import v1 from "../../services/axios";

export default function TradesTypeahead({ project, setProject, errors }) {
  const [trades, setTrades] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    getAllTrades();
  }, []);

  useEffect(() => {
    if (trades.length) {
      const selectedTrade = trades.find((trade) => {
        return trade.trade_id === project.trade_id;
      });

      setSelected(selectedTrade);
    }
  }, [project]);

  async function getAllTrades() {
    try {
      const { data, status } = await v1.get(`/contacts/trade/allTrades`);
      console.log({ data });
      if (status === 200) {
        let allTrades = [];
        for (let i = 0; i < data.categories.length; i++) {
          // allTrades.push({
          //   trade_name: data.categories[i].category,
          //   trade_id: -1,
          // });
          allTrades = allTrades.concat(
            data.categories[i].trades
              .filter((t) => !t.is_archived)
              .sort((a, b) => {
                let textA = a.trade_name.toUpperCase();
                let textB = b.trade_name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          );
        }

        allTrades.sort((a, b) => {
          if (a.trade_name > b.trade_name) {
            return 1;
          } else if (a.trade_name < b.trade_name) {
            return -1;
          } else {
            return 0;
          }
        });

        setTrades(allTrades);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleChange(trades) {
    if (trades[0] && trades[0].trade_id) {
      setSelected(trades[0]);
      setProject({ ...project, trade_id: trades[0].trade_id });
    }
  }

  return (
    <Typeahead
      id="tradeSearch"
      type="text"
      labelKey="trade_name"
      filterBy={["trade_name"]}
      minLength={0}
      placeholder="Search for a trade"
      className={`trades-typeahead ${errors.trade_id ? "invalid" : ""}`}
      onChange={handleChange}
      options={trades}
      selected={selected ? [selected] : []}
      renderMenuItemChildren={(trade) => (
        <div id={trade.trade_id} key={trade.trade_id}>
          <span>{trade.trade_name}</span>
        </div>
      )}
    />
  );
}
